import { faSearch, faTimes } from "@outschool/icons";
/**
 * Figma file: https://www.figma.com/file/vG9GNXiYwiuPpss0kVT8se/%E2%AD%90-Backpack-Components-(2023)?type=design&node-id=2545-32701&mode=design&t=020qhwN2bNZFv5CL-0
 *
 */
import React from "react";

import { THEME } from "../../theme";
import Box from "../Box/Box";
import { Button } from "../Button/Button";
import { IconButton, IconButtonProps } from "../IconButton/IconButton";
import {
  Input,
  InputProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";

type OSInputProps = {
  getInputProps?: () => object;
};

enum SearchBoxVariant {
  inApp = "InApp",
  navbar = "Navbar",
}

export interface SearchBoxProps {
  /**
   * Function to handle the form sending
   */
  onSubmit: (e: any) => void;
  /**
   * Function to clear out the input value
   */
  onClear: () => void;
  /**
   * Label to be set on the inApp variant button
   */
  searchLabel?: string;
  /**
   * Props forwarded to the input field
   */
  inputProps?: OSInputProps & InputProps;
  /**
   * Ref to the clear button that shows up when the value is present
   */
  clearSearchInputBoxRef?: React.RefObject<HTMLButtonElement>;
  /**
   * String for the aria label of the clear search icon
   */
  clearSearchAriaLabel?: string;
}

export function NavbarSearchBox(props: SearchBoxProps) {
  return <SearchBox {...props} variant={SearchBoxVariant.navbar} />;
}

export function InAppSearchBox(props: SearchBoxProps) {
  return <SearchBox {...props} variant={SearchBoxVariant.inApp} />;
}

export type SearchBoxComponentType =
  | typeof NavbarSearchBox
  | typeof InAppSearchBox;

function SearchBox({
  inputProps: {
    ref: inputRef,
    placeholder,
    value,
    onBlur,
    onFocus,
    onChange,
    spellCheck,
    getInputProps = () => ({}),
  } = {},
  onSubmit,
  onClear,
  clearSearchInputBoxRef,
  clearSearchAriaLabel = "Clear Search",
  searchLabel = "Search",
  variant,
}: SearchBoxProps & { variant: SearchBoxVariant }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        "&:focus-within": {
          borderColor: "primary.main",
        },
        padding: "2px",
        border: "2px solid transparent",
        borderRadius: "999px",
        width: "100%",
      }}
    >
      <Box
        component="form"
        sx={{
          backgroundColor: "white",
          border: "2px solid",
          borderColor: "neutral.200",
          borderRadius: "999px",
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "4px 4px 4px 20px",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow:
              "0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);",
          },
        }}
        onSubmit={onSubmit}
      >
        {variant === SearchBoxVariant.inApp && (
          <Responsive Desktop={<SearchIcon aria-label={searchLabel} />} />
        )}
        <Input
          disableUnderline
          fullWidth
          size={variant === SearchBoxVariant.navbar ? "small" : "medium"}
          variant="filled"
          type="text"
          inputProps={{
            sx: {
              paddingY: "5px",
              typography: "h6",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale",
              fontWeight: 400,
            },
          }}
          sx={{
            "&::placeholder": {
              color: "neutral.600",
            },
            "&:focus": {
              outline: "none",
              border: "none",
            },
            textOverflow: "ellipsis",
            background: "none",
            color: "neutral.900",
          }}
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          spellCheck={spellCheck}
          {...getInputProps()}
        />

        {variant === SearchBoxVariant.navbar && (
          <>
            <ClearInputButton
              value={value}
              buttonRef={clearSearchInputBoxRef}
              onClick={onClear}
              aria-label={clearSearchAriaLabel}
              size="small"
              sx={{
                fontSize: 20,
                padding: 5,
              }}
            />
            <SearchIcon aria-label={searchLabel} />
          </>
        )}
        {variant === SearchBoxVariant.inApp && (
          <>
            <ClearInputButton
              value={value}
              buttonRef={clearSearchInputBoxRef}
              onClick={onClear}
              aria-label={clearSearchAriaLabel}
              size={isMobile ? "small" : "large"}
              sx={(theme: Theme) => ({
                marginRight: 16,
                [theme.breakpoints.down("md")]: {
                  marginRight: 4,
                  padding: 5,
                  fontSize: 20,
                },
              })}
            />
            <Responsive
              Desktop={<SearchButton label={searchLabel} onSubmit={onSubmit} />}
              Mobile={
                <SearchIcon
                  aria-label={searchLabel}
                  variant="contained"
                  color="primary"
                />
              }
            />
          </>
        )}
      </Box>
    </Box>
  );
}

function ClearInputButton({
  value,
  buttonRef,
  sx,
  ...props
}: Omit<IconButtonProps, "icon"> & {
  value: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}) {
  if (!value) {
    return null;
  }

  return (
    <IconButton
      icon={faTimes}
      size="medium"
      variant="text"
      color="neutral"
      ref={buttonRef}
      sx={sx}
      {...props}
    />
  );
}

function SearchIcon({ sx, ...props }: Omit<IconButtonProps, "icon">) {
  return (
    <IconButton
      icon={faSearch}
      size="small"
      variant="text"
      color="neutral"
      sx={[
        {
          fontSize: 20,
          padding: 5,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      type="submit"
      {...props}
    />
  );
}

function SearchButton({
  label,
  onSubmit,
}: {
  label: string;
  onSubmit: (e: any) => void;
}) {
  return (
    <Button size="large" variant="contained" onClick={onSubmit}>
      {label}
    </Button>
  );
}

const desktopOnly = {
  [THEME.breakpoints.down("md")]: {
    display: "none",
  },
};

const mobileOnly = {
  [THEME.breakpoints.up("md")]: {
    display: "none",
  },
};

function Responsive({
  Mobile,
  Desktop,
}: {
  Mobile?: React.ReactNode;
  Desktop?: React.ReactNode;
}) {
  const mobile = Mobile ? <Box sx={mobileOnly}>{Mobile}</Box> : null;
  const desktop = Desktop ? <Box sx={desktopOnly}>{Desktop}</Box> : null;
  return (
    <>
      {mobile}
      {desktop}
    </>
  );
}
