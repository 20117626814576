import { Box } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { privacyPath, termsPath } from "@outschool/routes";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

export function AccountDisclaimer({ isSignup }: { isSignup: boolean }) {
  const Link = useLinkComponent();
  const { t } = useTranslation(
    "ui-components-shared\\src\\components\\AccountDisclaimer"
  );

  const message = isSignup ? (
    <Trans t={t}>
      By clicking Sign up or Continue with Google, Facebook, or Apple, you agree
      to Outschool’s{" "}
      <Link target="_blank" to={termsPath()}>
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link target="_blank" to={privacyPath()}>
        Privacy Policy
      </Link>
      .
    </Trans>
  ) : (
    <Trans t={t}>
      By clicking Log In or Continue with Google, Facebook, or Apple, you agree
      to Outschool’s{" "}
      <Link target="_blank" to={termsPath()}>
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link target="_blank" to={privacyPath()}>
        Privacy Policy
      </Link>
      .
    </Trans>
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridRowGap: "0.5em",
        textAlign: "center",
        color: "grey.700",
        justifyItems: "center",
      }}
    >
      <Box
        sx={{
          fontSize: "x-small",
          paddingBottom: "0.5em",
        }}
      >
        {message}
      </Box>
    </Box>
  );
}
