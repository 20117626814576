import { Icon, Snackbar, Theme } from "@outschool/backpack";
import { faTimes } from "@outschool/icons";
import React, { createContext, useContext, useState } from "react";

type SnackbarContextType = {
  addSnackbarContent: (content: React.ReactNode, durationMs?: number) => void;
  handleSnackbarClose?: (event: React.SyntheticEvent | Event) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  addSnackbarContent: () => {},
  handleSnackbarClose: () => {},
});

export const SnackbarProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [snackbarData, setSnackbarData] = useState<{
    isOpen: boolean;
    content?: React.ReactNode;
    durationMs: number;
  }>({
    isOpen: false,
    content: undefined,
    durationMs: 0,
  });

  const addSnackbarContent = (
    content: React.ReactNode,
    durationMs: number = 60000
  ) => {
    setSnackbarData({
      isOpen: true,
      content,
      durationMs,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarData({
      isOpen: false,
      content: undefined,
      durationMs: 0,
    });
  };

  return (
    <SnackbarContext.Provider
      value={{ addSnackbarContent, handleSnackbarClose }}
    >
      {children}
      <Snackbar
        open={snackbarData.isOpen}
        autoHideDuration={snackbarData.durationMs}
        onClose={handleSnackbarClose}
        message={snackbarData.content}
        action={
          <Icon
            onClick={handleSnackbarClose}
            icon={faTimes}
            size="medium"
            sx={{ cursor: "pointer" }}
          />
        }
        sx={(theme: Theme) => ({
          "a:hover:not(:disabled),a:focus:not(:disabled)": {
            color: "white",
            textDecoration: "none",
          },
          "&:hover span": {
            textDecoration: "underline",
          },
          [theme.breakpoints.down("md")]: {
            "& .MuiSnackbarContent-action": {
              position: "absolute",
              top: 12,
              right: 12,
            },
          },
        })}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
