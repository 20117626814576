/*
This file is all used for an experiment "ReferralSignUpBannerExperiment2", use anything here with caution.
 */
import { Box, Button, Icon, Theme, Typography } from "@outschool/backpack";
import { ParentReferrerFragment } from "@outschool/gql-frontend-generated";
import { faGift } from "@outschool/icons";
import { Trans, useTranslation } from "@outschool/localization";
import { parentPath } from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  useLoginFlowContext,
  useSession,
} from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";
import { useLinkComponent } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import React from "react";

import { ReferrerQuery } from "./ReferrerQuery";

function useOsReferralsCookie(): string | undefined {
  return Cookies.get("os-referrals");
}

const EXPERIMENT_NAME = "ReferralSignUpBannerExperiment2";

function useSignUpBannerExperiment(): { isVariant: boolean } {
  const { currentUserHasLoaded } = useSession();
  const { trigger, variant } = useExperiment({
    currentUserHasLoaded,
    experimentName: EXPERIMENT_NAME,
  });

  React.useEffect(() => {
    trigger();
  }, [trigger]);

  return { isVariant: variant === "treatment" };
}

function isOnReferrersProfile(referrerUid: string): boolean {
  // if this is not a parent profle, that's ok,
  // it will exit the condition to hide the profile when on the referring parents profile
  const isUserOnReferringParentsProfile =
    referrerUid && window
      ? (window?.location?.pathname || "").includes(referrerUid)
      : false;

  return isUserOnReferringParentsProfile;
}
export function SignUpBanner() {
  const { isLoggedIn, currentUser } = useSession();

  if (!isLoggedIn) {
    return <SignUpBannerFromCookie />;
  }

  if (!currentUser) {
    return null;
  }

  const referrer = currentUser.signUpCreditSummary?.referrer;

  if (
    !referrer ||
    !referrer?.parent ||
    isOnReferrersProfile(referrer.parent.uid)
  ) {
    return null;
  }

  return <LoggedInReferralBanner referrer={referrer.parent} />;
}

export function SignUpBannerFromCookie() {
  const usid = useOsReferralsCookie();

  const { data, loading } = useQueryWithPreviousData(ReferrerQuery, {
    variables: {
      referrerSlugId: usid,
    },
  });
  const referrer = data?.referrer;

  if (
    loading ||
    !referrer ||
    !referrer?.parent ||
    isOnReferrersProfile(referrer.parent.uid)
  ) {
    return null;
  }

  return (
    <TriggerExperimentWrapper>
      <LoggedOutMobileReferralBanner referrer={referrer?.parent} />
      <LoggedOutDesktopReferralBanner referrer={referrer?.parent} />
    </TriggerExperimentWrapper>
  );
}

function LoggedInReferralBanner({
  referrer,
}: {
  referrer: ParentReferrerFragment;
}) {
  const { currentUser } = useSession();
  const { t } = useTranslation(
    "ui-components-website\\Banners\\ReferralCreditBanner"
  );
  const Link = useLinkComponent();

  const { signUpCreditSummary = undefined } = currentUser || {};

  if (!signUpCreditSummary) {
    return null;
  }

  const daysLeft = dayjs(signUpCreditSummary.expiresAt).diff(dayjs(), "day");

  if (daysLeft < 0) {
    return null;
  }

  return (
    <TriggerExperimentWrapper>
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          width: "100%",
          padding: 5,
          backgroundColor: theme.palette.primary.light,
          justifyContent: "center",
          gap: "1rem",
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            alignSelf: "center",
            paddingTop: 2,
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          <Icon icon={faGift} />
        </Box>
        <Typography
          sx={(theme: Theme) => ({
            alignSelf: "center",
            paddingTop: 2,
            [theme.breakpoints.down("sm")]: {
              marginLeft: "1rem",
              marginRight: "1rem",
            },
          })}
        >
          {referrer.socialOptOut ? (
            <Trans t={t}>
              <Typography emphasized>
                {{ daysLeft: `${daysLeft}` }} days
              </Typography>{" "}
              left to use your <Typography emphasized>$20</Typography> credit
              from your <Link to={parentPath(referrer.uid)}>friend</Link>!
            </Trans>
          ) : (
            <Trans t={t}>
              <Typography emphasized>
                {{ daysLeft: `${daysLeft}` }} days
              </Typography>{" "}
              left to use your <Typography emphasized>$20</Typography> credit
              from{" "}
              <Typography>{{ referrerName: referrer.name?.trim() }}</Typography>
              !
            </Trans>
          )}
        </Typography>
      </Box>
    </TriggerExperimentWrapper>
  );
}

function TriggerExperimentWrapper({ children }: { children: React.ReactNode }) {
  const { isVariant } = useSignUpBannerExperiment();

  if (!isVariant) {
    return null;
  }
  return <>{children}</>;
}

function LoggedOutMobileReferralBanner({
  referrer,
}: {
  referrer: ParentReferrerFragment;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Banners\\ReferralCreditBanner"
  );
  const { enterLoginFlow } = useLoginFlowContext();
  const Link = useLinkComponent();

  const onClick = () =>
    enterLoginFlow({
      authTrigger: AuthTrigger.JOIN_OUTSCHOOL,
    });

  return (
    <Box
      sx={(theme: any) => ({
        display: "flex",
        // SSR clases have trustpilot banner which is janky for swapping out, overlay only on that page.
        ...(window.location.pathname.includes("/classes")
          ? { position: "absolute" }
          : {}),
        width: "100%",
        padding: 5,
        zIndex: 200,
        backgroundColor: theme.palette.primary[200],
        justifyContent: "space-around",
        gap: "1rem",
        [theme.breakpoints.up("sm")]: { display: "none" },
      })}
    >
      <Typography
        sx={{
          alignSelf: "center",
          paddingTop: 2,
        }}
      >
        {referrer.socialOptOut ? (
          <Trans t={t}>
            Claim your <Typography emphasized>$20</Typography> from your{" "}
            <Link to={parentPath(referrer.uid)}>friend!</Link>
          </Trans>
        ) : (
          <Trans t={t}>
            Claim your <Typography emphasized>$20</Typography> from{" "}
            <Link to={parentPath(referrer.uid)}>
              <Typography>
                {{ referrerName: referrer.name?.trim() }}!
              </Typography>
            </Link>
          </Trans>
        )}
      </Typography>
      <Button
        variant="outlined"
        onClick={onClick}
        sx={{ height: "4.4rem", alignSelf: "center" }}
      >
        {t("Join")}
      </Button>
    </Box>
  );
}

function LoggedOutDesktopReferralBanner({
  referrer,
}: {
  referrer: ParentReferrerFragment;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Banners\\ReferralCreditBanner"
  );
  const { enterLoginFlow } = useLoginFlowContext();
  const Link = useLinkComponent();

  const onClick = () =>
    enterLoginFlow({
      authTrigger: AuthTrigger.JOIN_OUTSCHOOL,
    });

  if (!referrer) {
    return null;
  }
  return (
    <Box
      sx={(theme: any) => ({
        display: "flex",
        width: "100%",
        padding: 5,
        backgroundColor: theme.palette.primary[200],
        gap: "1rem",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      })}
    >
      <Box
        sx={{
          alignSelf: "center",
          paddingTop: 2,
        }}
      >
        <Icon icon={faGift} />
      </Box>
      <Typography
        sx={(theme: Theme) => ({
          alignSelf: "center",
          paddingTop: 2,
          [theme.breakpoints.down("sm")]: {
            marginLeft: "1rem",
          },
        })}
      >
        {referrer.socialOptOut ? (
          <Trans t={t}>
            <Typography
              emphasized
              sx={(theme: Theme) => ({
                color: theme.palette.primary.dark,
                textDecoration: "underline",
              })}
              onClick={onClick}
            >
              Join now
            </Typography>{" "}
            to claim your <Typography emphasized>$20</Typography> credit from{" "}
            <Link to={parentPath(referrer.uid)}>friend!</Link>
          </Trans>
        ) : (
          <Trans t={t}>
            <Typography
              as="a"
              emphasized
              sx={(theme: Theme) => ({
                color: theme.palette.primary.dark,
                textDecoration: "underline",
                cursor: "pointer",
              })}
              onClick={onClick}
            >
              Join now
            </Typography>{" "}
            to claim your <Typography emphasized>$20</Typography> credit from{" "}
            <Link to={parentPath(referrer.uid)}>
              <Typography>
                {{ referrerName: referrer.name?.trim() }}!
              </Typography>
            </Link>
          </Trans>
        )}
      </Typography>
    </Box>
  );
}
