import { useLocale, useTranslation } from "@outschool/localization";
import {
  TrackedButton,
  TrackedButtonProps,
} from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

function HelpLink(
  {
    onClick,
    children,
    sx = {},
    trackingName,
    fullWidth = true,
    ...props
  }: TrackedButtonProps,
  ref: React.Ref<any>
) {
  const locale = useLocale();
  const { t } = useTranslation("ui-components-website\\Layout\\HelpLink");
  const navigate = useNavigation();
  const onButtonClickCallback = React.useCallback(onButtonClick, [
    locale,
    navigate,
    onClick,
  ]);

  async function onButtonClick(event: any) {
    if (onClick) {
      onClick(event);
    }

    const intercom = (window as any)?.Intercom;
    if (intercom) {
      try {
        intercom("show");
      } catch (_e) {
        navigate(`https://support.outschool.com/${locale}`, { newTab: true });
      }
    } else {
      navigate(`https://support.outschool.com/${locale}`, { newTab: true });
    }
  }

  return (
    <TrackedButton
      variant="link"
      color="inherit"
      sx={[
        {
          fontWeight: "fontWeightRegular",
          textAlign: "left",
          cursor: "pointer",
          verticalAlign: "baseline",
          textDecoration: "underline",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      fullWidth={fullWidth}
      onClick={onButtonClickCallback}
      ref={ref}
      trackingName={trackingName || "help_link"}
      {...props}
    >
      {children || t("Outschool Help")}
    </TrackedButton>
  );
}
export default React.forwardRef(HelpLink);
