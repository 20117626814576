import {
  getReferrerAttribution,
  getTrackingSessionUid,
} from "@outschool/attribution";
import { CookieKeys } from "@outschool/data-schemas";
import { getResolution } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";

import pkg from "../lib/pkg";
import {
  formatLocationInfo,
  getBotRequestInfo,
  getDeviceUid,
  logDebugEvent,
} from "../lib/util";
import {
  AnalyticsMode,
  AnalyticsModeSource,
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

import type { IPContext } from "@outschool/iplookup-client";
import type { ReferrerAttribution } from "@outschool/attribution";
import type { OutschoolBotInfo } from "@outschool/data-schemas";
import type { ScreenResolution } from "@outschool/ui-utils";
import type { Context } from "../types";
import type { LocationInfo } from "../lib/util";
import type { PageContext } from "../providers/PageContext";

type CommonEventProperties = {
  analyticsMode: AnalyticsMode;
  analyticsModeSource: AnalyticsModeSource;
  analyticsModeSourceOverride: AnalyticsModeSource | null;
  app: string;
  checkout_id?: string;
  deviceUid?: string;
  esa_id?: string;
  git_commit_id: string;
  integrations?: {
    [key: string]: boolean;
  };
  is_in_esa_session: boolean;
  internalTester: boolean;
  pageImpressionId: string;
  pageLoadId?: string;
  pagePath: string;
  pageCategory?: string;
  pageName?: string;
  referrerAttribution: ReferrerAttribution;
  resolution: ScreenResolution;
  trackingSessionUid?: string;
  loggedInUserExperimentUid?: string;
  loggedOutUserExperimentUid?: string;
} & OutschoolBotInfo &
  LocationInfo;

interface EnrichmentPluginParams {
  analyticsMode: AnalyticsMode;
  analyticsModeSource: AnalyticsModeSource;
  analyticsModeSourceOverride: AnalyticsModeSource | null;
  locationInfo: IPContext;
  page: PageContext;
}

export default class EnrichmentPlugin implements AnalyticsPlugin {
  name = Integration.Enrichment;
  category = IntegrationCategory.Functional;
  type = AnalyticsPluginType.enrichment;
  version = "0.1.0";

  analyticsMode: AnalyticsMode;
  analyticsModeSource: AnalyticsModeSource;
  analyticsModeSourceOverride: AnalyticsModeSource | null;
  botRequestInfo: OutschoolBotInfo;
  checkoutId?: string;
  locationInfo: IPContext;
  pageImpressionId: string;
  pageLoadId?: string;
  pagePath: string;
  pageCategory?: string;
  pageName?: string;
  pageEventSent: boolean = false;

  constructor({
    analyticsMode,
    analyticsModeSource,
    analyticsModeSourceOverride,
    locationInfo,
    page,
  }: EnrichmentPluginParams) {
    this.analyticsMode = analyticsMode;
    this.analyticsModeSource = analyticsModeSource;
    this.analyticsModeSourceOverride = analyticsModeSourceOverride;
    this.botRequestInfo = getBotRequestInfo();
    this.locationInfo = locationInfo;
    this.pageLoadId = page.pageLoadId;
    this.pageImpressionId = page.pageImpressionId;
    this.pagePath = page.pagePath;
    this.pageCategory = page.pageCategory;
    this.pageName = page.pageName;
  }

  isLoadable() {
    return true;
  }

  async load() {}
  async unload() {}

  isLoaded() {
    return true;
  }

  identify(context: Context): Context {
    logDebugEvent(context.event);
    return context;
  }

  page(context: Context): Context {
    const { category, name, properties } = context.event;

    this.pageCategory = category;
    this.pageImpressionId = properties?.pageImpressionId;
    this.pageName = name;
    this.pagePath = properties?.pagePath;

    return this.send(context);
  }

  track(context: Context): Context {
    return this.send(context);
  }

  send(context: Context): Context {
    Object.assign(
      context.event.properties || {},
      this.getCommonProperties(context)
    );

    logDebugEvent(context.event);

    return context;
  }

  getCommonProperties(context: Context): CommonEventProperties {
    if (!!this.pageName && !!context.event.properties?.page_name) {
      delete context.event.properties.page_name;
    }

    const properties: CommonEventProperties = {
      analyticsMode: this.analyticsMode,
      analyticsModeSource: this.analyticsModeSource,
      analyticsModeSourceOverride: this.analyticsModeSourceOverride,
      app: pkg.app,
      deviceUid: getDeviceUid(),
      git_commit_id: pkg.gitCommitId,
      integrations: this.getIntegrations(context?.event?.integrations),
      pageImpressionId: this.pageImpressionId,
      pageLoadId: this.pageLoadId,
      pageCategory: this.pageCategory,
      pageName: this.pageName,
      pagePath: this.pagePath,
      referrerAttribution: getReferrerAttribution(),
      resolution: getResolution(),
      trackingSessionUid: getTrackingSessionUid(),
      loggedInUserExperimentUid: Cookies.get(
        CookieKeys.LoggedInUserExperimentUid
      ),
      loggedOutUserExperimentUid: Cookies.get(
        CookieKeys.LoggedOutUserExperimentUid
      ),
      internalTester: Cookies.get(CookieKeys.InternalTester) === "true",
      ...this.botRequestInfo,
      ...formatLocationInfo(this.locationInfo?.ipInfo),
      ...this.getEsaSessionData(),
    };

    /*
     * `checkout_id` is a common event property on some standard eCommerce
     * events and it needs to be consistent throughout a user's entire
     * checkout flow.
     */
    if (context.event.event === "Checkout Started") {
      this.checkoutId = uuid();
    } else if (
      context.event.event === "Payment Info Entered" ||
      context.event.event === "Order Completed"
    ) {
      properties.checkout_id = this.getCheckoutId();
    }

    return properties;
  }

  getEsaSessionData(): { esa_id?: string; is_in_esa_session: boolean } {
    const cookie = Cookies.get(CookieKeys.EsaSession);
    const data = {
      esa_id: undefined,
      esa_state: undefined,
      is_in_esa_session: false,
    };

    try {
      const esa = JSON.parse(cookie || "null");

      data.esa_id = esa?.id;
      data.is_in_esa_session = !!esa?.id;
      data.esa_state = esa?.shipping?.state;
    } catch (_error) {}

    return data;
  }

  getIntegrations(
    eventIntegrations: Context["event"]["integrations"]
  ): CommonEventProperties["integrations"] {
    if (!eventIntegrations) {
      return undefined;
    }

    const integrations: CommonEventProperties["integrations"] = {};

    Object.keys(eventIntegrations).forEach(name => {
      integrations[name] = Boolean(eventIntegrations[name]);
    });

    return integrations;
  }

  getCheckoutId() {
    if (!this.checkoutId) {
      this.checkoutId = uuid();
    }

    return this.checkoutId;
  }
}
