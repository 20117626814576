import { CategoryPage } from "@outschool/gql-backend-generated";
import { useTranslation } from "@outschool/localization";
import { dayjs } from "@outschool/time";

export type CategoryPartial = Pick<CategoryPage, "nameTitleCased" | "slug"> &
  Partial<Omit<CategoryPage, "subcategories">> & {
    subcategories?: CategoryPartial[];
    includeOn?: string;
    excludeOn?: string;
    path?: string;
    showMore?: boolean; // To display the link "All `category` classes"
    can_link?: boolean;
    reloadDocument?: boolean;
  };

export function useAllNavbarCategories(): CategoryPartial[] {
  const { t } = useTranslation(
    "ui-components-website\\CategoryNavbar\\navbarCategories"
  );

  return [
    {
      nameTitleCased: t("Back To School"),
      path: "/landing-page/backtoschool",
      reloadDocument: true,
      slug: "backtoschool",
      showMore: true,
      can_link: true,
      subcategories: [
        {
          nameTitleCased: t("Schooling Approach"),
          slug: "schooling-approaches",
          subcategories: [
            { nameTitleCased: t("Homeschool"), slug: "homeschool" },
            { nameTitleCased: t("After School"), slug: "afterschool" },
          ],
        },
        {
          nameTitleCased: t("Learning Needs"),
          slug: "learning-needs",
          subcategories: [
            { nameTitleCased: t("Neurodiverse"), slug: "neurodiverse" },
            { nameTitleCased: t("ADHD"), slug: "adhd" },
            { nameTitleCased: t("Autism"), slug: "autism" },
            { nameTitleCased: t("Dyslexia"), slug: "dyslexia" },
          ],
        },
        {
          nameTitleCased: t("Learning Methods"),
          slug: "learning-methods",
          subcategories: [
            { nameTitleCased: t("Project-Based"), slug: "project-based" },
            { nameTitleCased: t("Hands-On"), slug: "hands-on" },
            { nameTitleCased: t("Discussion-Based"), slug: "discussion-based" },
            { nameTitleCased: t("Gamified"), slug: "gamified-learning" },
            { nameTitleCased: t("Unit Studies"), slug: "unit-studies" },
            { nameTitleCased: t("Montessori"), slug: "montessori" },
          ],
        },
        {
          nameTitleCased: t("Grade Levels"),
          slug: "grade-levels",
          subcategories: [
            {
              nameTitleCased: t("Pre-K & Kindergarten"),
              slug: "pre-k-and-kindergarten",
            },
            {
              nameTitleCased: t("Elementary School"),
              slug: "elementary-school",
            },
            { nameTitleCased: t("Middle School"), slug: "middle-school" },
            { nameTitleCased: t("High School"), slug: "high-school" },
          ],
        },
        {
          nameTitleCased: t("Topics"),
          slug: "popular",
          subcategories: [
            { nameTitleCased: t("Full Curriculum"), slug: "full-curriculum" },
            { nameTitleCased: t("Math"), slug: "math" },
            { nameTitleCased: t("English"), slug: "english" },
            { nameTitleCased: t("Spanish"), slug: "spanish" },
            { nameTitleCased: t("Drawing"), slug: "drawing" },
            { nameTitleCased: t("Video Games"), slug: "video-games" },
            { nameTitleCased: t("Arts & Crafts"), slug: "arts-and-crafts" },
            { nameTitleCased: t("Writing"), slug: "writing" },
            { nameTitleCased: t("Reading"), slug: "reading" },
            {
              nameTitleCased: t("Role Playing Games"),
              slug: "role-playing-games",
            },
            { nameTitleCased: t("Chess"), slug: "chess" },
            { nameTitleCased: t("Science"), slug: "science" },
            { nameTitleCased: t("Coding"), slug: "coding" },
            { nameTitleCased: t("Multiplication"), slug: "multiplication" },
            { nameTitleCased: t("Singing"), slug: "singing" },
            { nameTitleCased: t("Social Skills"), slug: "social-skills" },
            { nameTitleCased: t("Languages"), slug: "languages" },
          ],
        },
      ],
    },
    {
      nameTitleCased: t("Full Courses"),
      slug: "courses",
      showMore: true,
      can_link: true,
      reloadDocument: true,
      subcategories: [
        {
          nameTitleCased: t("Full Curriculum"),
          slug: "full-curriculum",
        },
        {
          nameTitleCased: t("English"),
          slug: "english-courses",
          subcategories: [
            { nameTitleCased: t("Reading"), slug: "reading-courses" },
            { nameTitleCased: t("Writing"), slug: "writing-courses" },
            { nameTitleCased: t("Debate"), slug: "debate-courses" },
          ],
        },
        {
          nameTitleCased: t("Math"),
          slug: "math-courses",
          subcategories: [
            {
              nameTitleCased: t("Addition & Subtraction"),
              slug: "addition-and-subtraction-courses",
            },
            {
              nameTitleCased: t("Multiplication & Division"),
              slug: "multiplication-and-division-courses",
            },
            { nameTitleCased: t("Algebra"), slug: "algebra-courses" },
            { nameTitleCased: t("Geometry"), slug: "geometry-courses" },
            { nameTitleCased: t("Calculus"), slug: "calculus-courses" },
          ],
        },
        {
          nameTitleCased: t("Coding & Tech"),
          slug: "coding-and-tech-fall-courses",
          subcategories: [
            {
              nameTitleCased: t("Beginner Coding"),
              slug: "beginner-coding-courses",
            },
            { nameTitleCased: t("Game Design"), slug: "game-design-courses" },
            {
              nameTitleCased: t("Python Coding"),
              slug: "python-coding-courses",
            },
          ],
        },
        {
          nameTitleCased: t("World Languages"),
          slug: "world-languages-courses",
          subcategories: [
            { nameTitleCased: t("Spanish"), slug: "spanish-courses" },
            { nameTitleCased: t("Japanese"), slug: "japanese-courses" },
            {
              nameTitleCased: t("Sign Language"),
              slug: "sign-language-courses",
            },
          ],
        },
        {
          nameTitleCased: t("Science & Nature"),
          slug: "science-courses",
          subcategories: [
            { nameTitleCased: t("Chemistry"), slug: "chemistry-courses" },
            { nameTitleCased: t("Biology"), slug: "biology-courses" },
            {
              nameTitleCased: t("Space Science"),
              slug: "space-and-astronomy-courses",
            },
          ],
        },
        {
          nameTitleCased: t("Life Skills"),
          slug: "life-skills-courses",
          subcategories: [
            {
              nameTitleCased: t("Social Skills"),
              slug: "social-skills-courses",
            },
            {
              nameTitleCased: t("Financial Literacy"),
              slug: "financial-literacy-courses",
            },
          ],
        },
        {
          nameTitleCased: t("Social Studies"),
          slug: "social-studies-courses",
          subcategories: [
            { nameTitleCased: t("History"), slug: "history-courses" },
            { nameTitleCased: t("Geography"), slug: "geography-courses" },
          ],
        },
        {
          nameTitleCased: t("Arts"),
          slug: "arts-and-crafts",
          subcategories: [
            {
              nameTitleCased: t("Film & Video"),
              slug: "film-and-video-courses",
            },
            { nameTitleCased: t("Photography"), slug: "photography-courses" },
            { nameTitleCased: t("Digital Art"), slug: "digital-art-courses" },
          ],
        },
        {
          nameTitleCased: t("Music"),
          slug: "music-courses",
          subcategories: [
            { nameTitleCased: t("Piano"), slug: "piano-courses" },
            { nameTitleCased: t("Singing"), slug: "singing-courses" },
            { nameTitleCased: t("Music Theory"), slug: "music-theory-courses" },
          ],
        },
        {
          nameTitleCased: t("Games & Hobbies"),
          slug: "games-and-hobbies",
          subcategories: [
            { nameTitleCased: t("Chess"), slug: "chess-courses" },
            {
              nameTitleCased: t("Role Playing Games"),
              slug: "role-playing-games-courses",
            },
          ],
        },
        {
          nameTitleCased: t("Health & Wellness"),
          slug: "health-and-wellness-courses",
        },
        {
          nameTitleCased: t("Test Prep"),
          slug: "test-prep-courses",
        },
        {
          nameTitleCased: t("Self-Paced"),
          slug: "self-paced-courses",
          subcategories: [
            { nameTitleCased: t("English"), slug: "self-paced-english" },
            { nameTitleCased: t("Math"), slug: "self-paced-math" },
            { nameTitleCased: t("Science"), slug: "self-paced-science" },
            { nameTitleCased: t("Art"), slug: "self-paced-art" },
          ],
        },
      ],
    },
    {
      nameTitleCased: t("Tutoring & Private Lessons"),
      slug: "tutoring",
      showMore: true,
      can_link: true,
      subcategories: [
        {
          nameTitleCased: t("English"),
          slug: "english-tutoring",
          subcategories: [
            { nameTitleCased: t("Reading"), slug: "reading-tutoring" },
            { nameTitleCased: t("Writing"), slug: "writing-tutoring" },
            { nameTitleCased: t("Phonics"), slug: "phonics-tutoring" },
          ],
        },
        {
          nameTitleCased: t("Music"),
          slug: "music-private-lessons",
          subcategories: [
            { nameTitleCased: t("Piano"), slug: "piano-private-lessons" },
            { nameTitleCased: t("Singing"), slug: "singing-private-lessons" },
            { nameTitleCased: t("Guitar"), slug: "guitar-private-lesson" },
          ],
        },
        {
          nameTitleCased: t("Math"),
          slug: "math-tutoring",
          subcategories: [
            { nameTitleCased: t("Early Math"), slug: "early-math-tutoring" },
            { nameTitleCased: t("Algebra"), slug: "algebra-tutoring" },
            { nameTitleCased: t("Geometry"), slug: "geometry-tutoring" },
          ],
        },
        {
          nameTitleCased: t("Languages"),
          slug: "languages-tutoring",
          subcategories: [
            { nameTitleCased: t("Spanish"), slug: "spanish-tutoring" },
            { nameTitleCased: t("English"), slug: "english-esl-tutoring" },
            { nameTitleCased: t("Chinese"), slug: "chinese-tutoring" },
          ],
        },
        {
          nameTitleCased: t("Life Skills"),
          slug: "life-skills-tutoring",
          subcategories: [
            {
              nameTitleCased: t("Social Skills"),
              slug: "social-skills-tutoring",
            },
            {
              nameTitleCased: t("Study Skills"),
              slug: "study-skills-tutoring",
            },
            { nameTitleCased: t("Handwriting"), slug: "handwriting-tutoring" },
          ],
        },
        {
          nameTitleCased: t("Coding & Tech"),
          slug: "coding-and-tech-tutoring",
          subcategories: [
            { nameTitleCased: t("Coding"), slug: "coding-tutoring" },
            {
              nameTitleCased: t("Video Game Design"),
              slug: "video-game-design-tutoring",
            },
          ],
        },
        {
          nameTitleCased: t("Science"),
          slug: "science-tutoring",
          subcategories: [
            { nameTitleCased: t("Chemistry"), slug: "chemistry-tutoring" },
            { nameTitleCased: t("Biology"), slug: "biology-tutoring" },
            { nameTitleCased: t("Physics"), slug: "physics-tutoring" },
          ],
        },
        {
          nameTitleCased: t("Health & Wellness"),
          slug: "health-and-wellness-tutoring",
        },
        { nameTitleCased: t("Test Prep"), slug: "test-prep-tutoring" },
        {
          nameTitleCased: t("Games & Hobbies"),
          slug: "games-and-hobbies-private-lessons",
          subcategories: [
            { nameTitleCased: t("Chess"), slug: "chess-private" },
            {
              nameTitleCased: t("Video Games"),
              slug: "video-games-private-lessons",
            },
          ],
        },
        {
          nameTitleCased: t("Social Studies"),
          slug: "social-studies-tutoring",
          subcategories: [
            { nameTitleCased: t("History"), slug: "history-tutoring" },
            { nameTitleCased: t("Geography"), slug: "geography-tutoring" },
          ],
        },
        {
          nameTitleCased: t("Arts"),
          slug: "arts-tutoring",
          subcategories: [
            { nameTitleCased: t("Drawing"), slug: "drawing-private-lesson" },
            {
              nameTitleCased: t("Theater & Acting"),
              slug: "acting-private-lessons",
            },
            { nameTitleCased: t("Dance"), slug: "dance-private" },
          ],
        },
      ],
    },
    {
      nameTitleCased: t("Clubs & Enrichment"),
      slug: "clubs",
      showMore: true,
      can_link: true,
      subcategories: [
        {
          nameTitleCased: t("English"),
          slug: "english-clubs",
          subcategories: [
            {
              nameTitleCased: t("Creative Writing"),
              slug: "creative-writing-clubs",
            },
            { nameTitleCased: t("Book Clubs"), slug: "book-clubs" },
            {
              nameTitleCased: t("Public Speaking"),
              slug: "public-speaking-clubs",
            },
          ],
        },
        {
          nameTitleCased: t("Arts"),
          slug: "art-clubs",
          subcategories: [
            { nameTitleCased: t("Drawing"), slug: "drawing-clubs" },
            { nameTitleCased: t("Digital Art"), slug: "digital-art-clubs" },
            { nameTitleCased: t("Fashion"), slug: "fashion-clubs" },
            { nameTitleCased: t("Crafts"), slug: "crafts-clubs" },
          ],
        },
        {
          nameTitleCased: t("Life Skills"),
          slug: "life-skills-clubs",
          subcategories: [
            { nameTitleCased: t("Social Clubs"), slug: "social-clubs" },
            {
              nameTitleCased: t("Cooking & Baking"),
              slug: "cooking-and-baking-clubs",
            },
            {
              nameTitleCased: t("Critical Thinking"),
              slug: "critical-thinking-clubs",
            },
          ],
        },
        {
          nameTitleCased: t("Math"),
          slug: "math-clubs",
          subcategories: [
            {
              nameTitleCased: t("Addition & Subtraction"),
              slug: "addition-and-subtraction-clubs",
            },
            {
              nameTitleCased: t("Multiplication & Division"),
              slug: "multiplication-and-division-clubs",
            },
            { nameTitleCased: t("Algebra"), slug: "algebra-clubs" },
            { nameTitleCased: t("Geometry"), slug: "geometry-clubs" },
          ],
        },
        {
          nameTitleCased: t("World Languages"),
          slug: "language-clubs",
          subcategories: [
            { nameTitleCased: t("Spanish"), slug: "spanish-clubs" },
            { nameTitleCased: t("French"), slug: "french-clubs" },
            { nameTitleCased: t("Sign Language"), slug: "sign-language-clubs" },
          ],
        },
        {
          nameTitleCased: t("Music"),
          slug: "music-clubs",
          subcategories: [
            { nameTitleCased: t("Singing"), slug: "singing-clubs" },
            { nameTitleCased: t("Piano"), slug: "piano-clubs" },
            { nameTitleCased: t("Guitar"), slug: "guitar-clubs" },
          ],
        },
        {
          nameTitleCased: t("Science & Nature"),
          slug: "science-clubs",
          subcategories: [
            { nameTitleCased: t("Biology"), slug: "biology-clubs" },
            { nameTitleCased: t("STEM & STEAM"), slug: "stem-clubs" },
            {
              nameTitleCased: t("Space & Astronomy"),
              slug: "space-and-astronomy-clubs",
            },
          ],
        },
        {
          nameTitleCased: t("Coding & Tech"),
          slug: "coding-and-tech-clubs",
          subcategories: [
            {
              nameTitleCased: t("Beginner Coding"),
              slug: "beginner-coding-clubs",
            },
            { nameTitleCased: t("Python Coding"), slug: "python-coding-clubs" },
            { nameTitleCased: t("Engineering"), slug: "engineering-clubs" },
          ],
        },
        {
          nameTitleCased: t("Social Studies"),
          slug: "social-studies-clubs",
          subcategories: [
            { nameTitleCased: t("Geography"), slug: "geography-clubs" },
            {
              nameTitleCased: t("Current Events"),
              slug: "current-events-clubs",
            },
          ],
        },
        {
          nameTitleCased: t("Games & Hobbies"),
          slug: "games-and-hobbies-clubs",
          subcategories: [
            { nameTitleCased: t("Video Games"), slug: "video-games-clubs" },
            {
              nameTitleCased: t("Role Playing Games"),
              slug: "role-playing-games-clubs",
            },
          ],
        },
        {
          nameTitleCased: t("Health & Wellness"),
          slug: "health-and-wellness-clubs",
          subcategories: [
            {
              nameTitleCased: t("Sports & Fitness"),
              slug: "sports-and-fitness-clubs",
            },
            {
              nameTitleCased: t("Mental & Emotional Health"),
              slug: "mental-and-emotional-health-clubs",
            },
          ],
        },
        {
          nameTitleCased: t("Test Prep"),
          slug: "test-prep-clubs",
        },
      ],
    },
  ] as CategoryPartial[];
}

const dateFormat: string = "MM/DD/YY";

export function removeCategoriesByDate(category: CategoryPartial) {
  if (category.subcategories) {
    return {
      ...category,
      subcategories: filterByDate(category.subcategories),
    };
  } else {
    return category;
  }
}

export function filterByDate(categories: CategoryPartial[]) {
  const dayjsObj = dayjs();
  return categories!
    .filter(cat => {
      if (!cat.hasOwnProperty("includeOn")) {
        return true;
      }
      const includeDate = dayjs(cat.includeOn, dateFormat);
      return dayjsObj.isSameOrAfter(includeDate);
    })
    .filter(cat => {
      if (!cat.hasOwnProperty("excludeOn")) {
        return true;
      }
      const excludeDate = dayjs(cat.excludeOn, dateFormat);
      return dayjsObj.isSameOrBefore(excludeDate);
    })
    .map(cat => {
      const newCategory = { ...cat };
      delete newCategory.excludeOn;
      delete newCategory.includeOn;
      return newCategory;
    });
}

export default function useNavbarCategoriesFiltered() {
  const categories = useAllNavbarCategories();

  return filterByDate(categories).map(removeCategoriesByDate);
}
