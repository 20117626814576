import { Box, Button } from "@outschool/backpack";
import {
  FundingProgramAbbreviation,
  getFundingProgramProperties,
} from "@outschool/funding-programs-shared";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { ExternalLink, useFeatureFlag } from "@outschool/ui-components-shared";
import {
  Banner,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";

export default function KansasKeepFundingBanner() {
  const ffShowBanner = useFeatureFlag(
    "esp_self-serve_invoicing_for_kansas_keep"
  );
  const { t } = useTranslation(
    "ui-components-website\\Banners\\KansasKeepFundingBanner"
  );

  const meritKeepSession = Cookies.get("meritKeepSession");
  const [isOptedIn, setIsOptedIn] = useState(!!meritKeepSession);

  const bannerRef = useRef(null);
  const trackEvent = useTrackEvent();
  useImpressionTracking({
    node: bannerRef.current,
    trackingLabel: "kansas_keep_funding_banner",
    uniqueId: "kansas_keep_funding_banner",
    sharedProperties: { isOptedIn },
    trackViews: true,
  });

  const keepProgramAbbr = FundingProgramAbbreviation.KS;

  if (!ffShowBanner) {
    return null;
  }

  if (meritKeepSession === "direct") {
    // No banner for users referred directly from Merit
    return null;
  }

  const fundingProgramProperties = getFundingProgramProperties(keepProgramAbbr);
  const programNameLong = fundingProgramProperties?.programNameLong;
  const programUrl = fundingProgramProperties?.programUrl || "#";

  const handleOptIn = () => {
    Cookies.set("meritKeepSession", "optIn");
    setIsOptedIn(true);
    trackEvent("kansas_keep_banner_opt_in_touch");
  };

  const handleOptOut = () => {
    Cookies.remove("meritKeepSession");
    setIsOptedIn(false);
    trackEvent("kansas_keep_banner_opt_out_touch");
  };

  return (
    <Banner data-test-id="keep-funding-banner" closable={true} ref={bannerRef}>
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isOptedIn ? (
            <>
              <Button
                variant="link"
                sx={{
                  fontSize: "1em",
                  marginLeft: "0.5rem",
                }}
                onClick={handleOptOut}
              >
                {t("Exit {{programNameLong}} session", {
                  programNameLong,
                })}
              </Button>
              &nbsp;
              <Box>{t("to pay by other methods.")}</Box>
            </>
          ) : (
            <>
              <Box>
                {t("Would you like to use your {{programNameLong}} funds?", {
                  programNameLong,
                })}
              </Box>
              <Button
                variant="link"
                sx={{
                  fontSize: "1em",
                  marginLeft: "0.5rem",
                }}
                onClick={handleOptIn}
              >
                {t("Opt in")}
              </Button>
              &nbsp;
              <Box>{t("or")}</Box>
              &nbsp;
              <ExternalLink url={programUrl}>{t("learn more")}</ExternalLink>
            </>
          )}
        </Box>
      </Box>
    </Banner>
  );
}
