import { useFeatureFlag } from "@outschool/ui-components-shared";
import React from "react";

import useAnalyticsSettings from "../hooks/settings/useAnalyticsSettings";
import useTrackEvent from "../hooks/useTrackEvent";
import useIpLocation from "../hooks/util/useIpLocation";
import usePageTracking from "../hooks/util/usePageTracking";
import usePerformanceObservers from "../hooks/util/usePerformanceObservers";
import pkg from "../lib/pkg";
import trackAnalyticsInitialized from "../lib/track";
import { AnalyticsError, logDebug } from "../lib/util";
import {
  AnalyticsMode,
  AnalyticsModeSource,
  AnalyticsStatus,
  addLoadDurationEntry,
  getLoadDurationList,
  loadAnalytics,
  useAnalyticsContext,
} from "../providers/AnalyticsContext";
import { usePageContext } from "../providers/PageContext";
import CookieConsent from "./CookieConsent";

import type { EventProperties } from "../types";

let initializationStartTime: number;
let loadDurationEventSent = false;

export default function AnalyticsLoader() {
  const page = usePageContext();
  const { initialized, setStatus, status } = useAnalyticsContext();
  const locationInfo = useIpLocation();
  const trackEvent = useTrackEvent();
  const {
    analyticsMode,
    analyticsModeSource,
    analyticsModeSourceOverride,
    analyticsSettingsLoaded,
    updateAnalyticsSettings,
  } = useAnalyticsSettings();

  usePageTracking();
  usePerformanceObservers();

  const newCookieConsentEnabled = useFeatureFlag("lxw-cookiebot-banner");

  const isCookieConsentRequiredCallback = React.useCallback(
    isCookieConsentRequired,
    [analyticsMode, analyticsModeSource, newCookieConsentEnabled]
  );
  const onAnalyticsLoadedCallback = React.useCallback(onAnalyticsLoaded, [
    setStatus,
  ]);

  function isCookieConsentRequired() {
    return (
      !newCookieConsentEnabled &&
      analyticsModeSource === AnalyticsModeSource.CookieSettings &&
      analyticsMode === AnalyticsMode.Unknown
    );
  }

  function onAnalyticsLoaded(error: Error) {
    if (error) {
      setStatus(AnalyticsStatus.Failed);
      const err = new AnalyticsError("Failed to load", error);
      pkg.onError(err);
      return;
    }

    setStatus(AnalyticsStatus.Ready);
  }

  React.useEffect(() => {
    if (!initializationStartTime) {
      initializationStartTime = Date.now();
    }

    if (!analyticsSettingsLoaded || !page.ready) {
      return;
    }

    if (status === AnalyticsStatus.Init) {
      setStatus(AnalyticsStatus.Loading);

      logDebug("AnalyticsModeSource:", analyticsModeSource);

      if (!!analyticsModeSourceOverride) {
        logDebug("AnalyticsModeSourceOverride:", analyticsModeSourceOverride);
      }

      logDebug("AnalyticsMode:", analyticsMode);

      loadAnalytics({
        analyticsMode,
        analyticsModeSource,
        analyticsModeSourceOverride,
        callback: onAnalyticsLoadedCallback,
        locationInfo,
        page,
      });
    }
  }, [
    analyticsMode,
    analyticsModeSource,
    analyticsModeSourceOverride,
    analyticsSettingsLoaded,
    locationInfo,
    onAnalyticsLoadedCallback,
    page,
    setStatus,
    status,
  ]);

  React.useEffect(() => {
    if (!initialized || loadDurationEventSent) {
      return;
    }

    addLoadDurationEntry("initialization", initializationStartTime);

    const loadDurationList = getLoadDurationList();
    const properties: EventProperties = { analyticsStatus: status };

    loadDurationList.forEach(entry => {
      properties[`${entry.label}Duration`] = entry.duration;
    });

    /*
     * Send a server-side analytics-initialized event if analytics has failed
     * to load. Otherwise, send the usual client-side event.
     */
    if (status === AnalyticsStatus.Failed) {
      trackAnalyticsInitialized(page, {
        ...properties,
        analyticsMode,
        analyticsModeSource,
        analyticsModeSourceOverride,
      });
    } else {
      trackEvent("analytics-initialized", properties);
    }

    loadDurationEventSent = true;
  }, [
    analyticsMode,
    analyticsModeSource,
    analyticsModeSourceOverride,
    initialized,
    page,
    status,
    trackEvent,
  ]);

  return isCookieConsentRequiredCallback() ? (
    <CookieConsent onSubmit={updateAnalyticsSettings} />
  ) : null;
}
