import CountdownTimer from "../CountdownTimer";
import { getReferrerAttribution } from "@outschool/attribution";
import { Box, Icon, IconButton, Theme, Typography } from "@outschool/backpack";
import { CookieKeys } from "@outschool/data-schemas";
import {
  LoggedInOrOutCanApplyCouponQuery,
  LoggedInOrOutCanApplyCouponQueryVariables,
} from "@outschool/gql-frontend-generated";
import { IconDefinition, faX } from "@outschool/icons";
import { Trans, useTranslation } from "@outschool/localization";
import { dayjs } from "@outschool/time";
import { gql, useQuery } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

const EXPERIMENT_NAME = "PromoOfferBanner";
const PRIMARY_COLOR = "primary.main";
const SECONDARY_COLOR = "grey.100";
const COOKIE_KEY = "PromotionalOfferBannerHasBeenDismissed";

type PromotionalOfferBannerCampaigns = "august2024" | undefined;

// When marketing runs a new campaign, update this to be the SSR + CSR source of truth.
// Any calling component may choose a different PromotionalOfferBannerCampaigns variant.
export const CURRENT_PROMOTIONAL_OFFER_BANNER_CAMPAIGN: PromotionalOfferBannerCampaigns =
  "august2024";

type PromotionalOfferBannerExperimentProps = {
  campaignVariant?: PromotionalOfferBannerCampaigns;
  promotionalOfferBannerData?: LoggedInOrOutCanApplyCouponQuery;
  isProduction: boolean; // Required to know which coupon to use
};
type PromotionalOfferBannerSSRProps = {
  campaignVariant?: PromotionalOfferBannerCampaigns;
  promotionalOfferBannerData: LoggedInOrOutCanApplyCouponQuery;
  isProduction: boolean;
};
type PromotionalOfferBannerCSRProps = {
  campaignVariant?: PromotionalOfferBannerCampaigns;
  isProduction: boolean;
};
type PromotionalOfferBannerProps = {
  campaignVariant?: PromotionalOfferBannerCampaigns;
  promotionalOfferBannerData: LoggedInOrOutCanApplyCouponQuery;
  isProduction: boolean;
};

/**
 * SSR-CloudFlare-Worker assigns experimentVariants per anonymous-id,
 * and passes experimentName & experimentVariantAssigned to SSR only.
 * When this banner in loaded via SSR, the experimentName & experimentVariantAssigned
 * are passed into the initialization of the ExperimentServiceProvider.
 * Therefore, only on CSR will trigger a new experiment service network call,
 * and SSR will use the already-defined variant.
 */
export default function PromotionalOfferBannerExperiment({
  campaignVariant,
  promotionalOfferBannerData,
  isProduction,
}: PromotionalOfferBannerExperimentProps) {
  const { currentUserHasLoaded } = useSession();

  const { variant: experimentVariant, trigger } = useExperiment({
    experimentName: EXPERIMENT_NAME,
    currentUserHasLoaded,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  if (experimentVariant === "treatment") {
    // SSR will fetch the data and pass it in.
    if (!!promotionalOfferBannerData) {
      return (
        <PromotionalOfferBannerSSR
          campaignVariant={campaignVariant}
          promotionalOfferBannerData={promotionalOfferBannerData}
          isProduction={isProduction}
        />
      );
      // CSR must make its own fetch. SSR falls back to CSR behaviour.
    } else {
      return (
        <PromotionalOfferBannerCSR
          campaignVariant={campaignVariant}
          isProduction={isProduction}
        />
      );
    }
  }
  return null;
}

type BannerCampaign = {
  couponCode: string;
  couponUid: string;
  icon: IconDefinition | null;
  hideDays: boolean;
  autoApplyAtCheckout: boolean;
};

export const canUserRedeemCouponQuery = gql`
  query LoggedInOrOutCanApplyCoupon($couponUid: String!, $userUid: String) {
    coupon(uid: $couponUid) {
      uid
      expiresAt
      userIsEligibleToApplyCoupon(userUid: $userUid) {
        isEligible
      }
    }
  }
`;

function addCouponCodeToCookie(
  attribution: any,
  couponCode: string,
  autoApplyAtCheckout: boolean
) {
  // Do not override existing coupons that are being auto-applied at checkout
  // or when the campaign doesn't want this behaviour
  if (!!attribution.couponCode || !autoApplyAtCheckout) {
    return;
  }
  attribution.couponCode = couponCode;
  Cookies.set(CookieKeys.OsRef, JSON.stringify(attribution));
}

function removeCouponCodeFromCookie(
  attribution: any,
  couponCode: string,
  autoApplyAtCheckout: boolean
) {
  // Do not override existing coupons that are being auto-applied at checkout
  // or when the campaign doesn't want this behaviour
  if (attribution.couponCode !== couponCode || !autoApplyAtCheckout) {
    return;
  }
  attribution.couponCode = "";
  Cookies.set(CookieKeys.OsRef, JSON.stringify(attribution));
}

export function getBannerCampaign(
  campaignVariant: PromotionalOfferBannerCampaigns,
  isProduction: boolean
): BannerCampaign {
  let bannerCampaign: BannerCampaign;

  switch (campaignVariant) {
    case "august2024":
    default:
      // PROD Coupon: https://metabase.outschool.dev/question/34613-august2024-coupon-code
      bannerCampaign = {
        icon: null,
        couponUid: isProduction
          ? "9da99c76-1d75-479e-981a-6a88b6524f91"
          : "91e4a2bd-fe9a-494c-b870-96147c1da9f4",
        couponCode: isProduction ? "AUGUST2024" : "AUG20STAGE",
        hideDays: true,
        autoApplyAtCheckout: false,
      };
      break;
  }
  return bannerCampaign;
}

function BannerCampaignMessage({
  campaignVariant,
  couponCode,
}: {
  campaignVariant: PromotionalOfferBannerCampaigns;
  couponCode: string;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Banners\\PromotionalOfferBannerExperiment"
  );
  switch (campaignVariant) {
    case "august2024":
    default:
      return (
        <Typography variant="subtitle2" sx={{ fontSize: "inherit" }}>
          <Trans t={t}>
            SAVE up to $20 on your first class with code <b>{couponCode}</b> -
            24 hours only! Terms apply.
          </Trans>
        </Typography>
      );
  }
}

/**
 * On SSR, the query is already fetched, pass it in.
 * Similarly, there is no local storage server side, so
 * banner dismissal is not peristant, and only held in state.
 */
function PromotionalOfferBannerSSR({
  campaignVariant,
  promotionalOfferBannerData,
  isProduction,
}: PromotionalOfferBannerSSRProps) {
  return (
    <PromotionalOfferBanner
      campaignVariant={campaignVariant}
      promotionalOfferBannerData={promotionalOfferBannerData}
      isProduction={isProduction}
    />
  );
}

/**
 * On CSR, you must fetch the data.
 * Local storage is used client side to respect banner dismissal.
 */
function PromotionalOfferBannerCSR({
  campaignVariant,
  isProduction,
}: PromotionalOfferBannerCSRProps) {
  const userUid = useSession().currentUser?.uid;
  let bannerCampaign = getBannerCampaign(campaignVariant, isProduction);
  const { data, loading, error } = useQuery<
    LoggedInOrOutCanApplyCouponQuery,
    LoggedInOrOutCanApplyCouponQueryVariables
  >(canUserRedeemCouponQuery, {
    variables: {
      userUid,
      couponUid: bannerCampaign.couponUid,
    },
    initialFetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  if (loading || error || data === undefined) {
    return null;
  }

  return (
    <PromotionalOfferBanner
      campaignVariant={campaignVariant}
      promotionalOfferBannerData={data}
      isProduction={isProduction}
    />
  );
}

/**
 * The UI for the promotional offer banner.
 */
function PromotionalOfferBanner({
  campaignVariant,
  promotionalOfferBannerData: data,
  isProduction,
}: PromotionalOfferBannerProps) {
  const bannerCampaign = getBannerCampaign(campaignVariant, isProduction);
  const [bannerHasBeenDismissed, setBannerHasBeenDismissed] = useState(
    Cookies.get(COOKIE_KEY) === "true"
  );
  const { expiresAt, userIsEligibleToApplyCoupon } = data?.coupon || {};
  const canRedeemCoupon = userIsEligibleToApplyCoupon.isEligible;
  const attribution = getReferrerAttribution();

  /** Sets the os-ref couponCode to auto-apply at checkout */
  useEffect(() => {
    if (canRedeemCoupon && !bannerHasBeenDismissed) {
      addCouponCodeToCookie(
        attribution,
        bannerCampaign.couponCode,
        bannerCampaign.autoApplyAtCheckout
      );
    } else if (!canRedeemCoupon) {
      removeCouponCodeFromCookie(
        attribution,
        bannerCampaign.couponCode,
        bannerCampaign.autoApplyAtCheckout
      );
    }
  }, [
    canRedeemCoupon,
    bannerHasBeenDismissed,
    attribution,
    bannerCampaign.couponCode,
    bannerCampaign.autoApplyAtCheckout,
  ]);

  function hideBanner() {
    removeCouponCodeFromCookie(
      attribution,
      bannerCampaign.couponCode,
      bannerCampaign.autoApplyAtCheckout
    );
    Cookies.set(COOKIE_KEY, "true", {
      expires: 7,
      path: "",
    });
    setBannerHasBeenDismissed(true);
  }

  if (!canRedeemCoupon || bannerHasBeenDismissed) {
    return null;
  }

  return (
    <Box
      flex
      sx={{
        width: "100%",
        justifyContent: "space-between",
        backgroundColor: PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        overflow: "hidden",
        padding: "0 1rem",
        minHeight: "4.7rem",
        height: "auto",
      }}
    >
      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          padding: "0.25rem 0",
          width: "100%",
          maxWidth: "lg",
        }}
      >
        <Box
          flex
          sx={(theme: Theme) => ({
            justifyContent: "center",
            alignItems: "center",
            margin: "0 0.25rem",
            height: "100%",
            [theme.breakpoints.up("sm")]: {
              margin: "0rem 1rem",
            },
          })}
        >
          <Box
            flex
            sx={(theme: Theme) => ({
              flexDirection: "row",
              marginRight: "0",
              height: "100%",
              alignItems: "center",
              [theme.breakpoints.up("sm")]: {
                marginLeft: "1rem",
                marginRight: "1rem",
              },
            })}
          >
            {bannerCampaign.icon ? (
              <Icon
                icon={bannerCampaign.icon}
                sx={(theme: Theme) => ({
                  display: "none",
                  alignSelf: "center",
                  [theme.breakpoints.up("sm")]: {
                    marginRight: "1rem",
                    display: "block",
                  },
                })}
              />
            ) : (
              <Box
                sx={(theme: Theme) => ({
                  display: "none",
                  alignSelf: "center",
                  [theme.breakpoints.up("sm")]: {
                    marginRight: "1rem",
                    display: "block",
                  },
                })}
              >
                🎉
              </Box>
            )}
            <Box
              component="span"
              sx={(theme: Theme) => ({
                width: "100%",
                fontSize: "1.2rem",
                marginRight: "1rem",
                [theme.breakpoints.up("sm")]: {
                  fontSize: "1.5rem",
                  textAlign: "center",
                },
              })}
            >
              <BannerCampaignMessage
                campaignVariant={campaignVariant}
                couponCode={bannerCampaign.couponCode}
              />
            </Box>
          </Box>
          {expiresAt && (
            <CountdownTimer
              deadline={dayjs(expiresAt)}
              primaryColor={PRIMARY_COLOR}
              secondaryColor={SECONDARY_COLOR}
              hideDays={bannerCampaign.hideDays}
            />
          )}
        </Box>
      </Box>

      <IconButton
        icon={faX}
        variant="outlined"
        size="small"
        sx={{
          color: PRIMARY_COLOR,
          backgroundColor: SECONDARY_COLOR,
          height: "1rem",
          width: "1rem",
          alignSelf: "center",
          marginLeft: "0.5rem",
          border: "none",
          fontSize: "1rem",
        }}
        onClick={hideBanner}
      />
    </Box>
  );
}
