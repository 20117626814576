import { gql } from "@outschool/ui-apollo";

export const ParentReferrerFragment = gql`
  fragment ParentReferrer on Parent {
    uid
    name
    photo
    publicName
    introduction
    created_at
    deleted_at
    timeZone
    socialOptOut
  }
`;
export const LeaderReferrerFragment = gql`
  fragment LeaderReferrer on Leader {
    uid
    name
    leader_link
    created_at
    photo
    approved
    socialOptOut
    hasBackgroundCheckExpired
  }
`;

export const ReferrerQuery = gql`
  query Referrer($referrerSlugId: String) {
    referrer: publicProfileNew(slug_id: $referrerSlugId) {
      uid
      parent {
        ...ParentReferrer
      }
      leader {
        ...LeaderReferrer
      }
    }
  }
  ${ParentReferrerFragment}
  ${LeaderReferrerFragment}
`;
