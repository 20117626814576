import { pxToRem } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import LegacyButton, { LegacyButtonVariant } from "./LegacyButton/LegacyButton";

export interface ToggleButtonProps extends Object {
  enabled: boolean;
  variant?: LegacyButtonVariant;
  sx?: SystemStyleObject;
}

export default React.forwardRef<any, any>(
  ({ enabled, ...props }: ToggleButtonProps, ref) => {
    const variant = props.variant || "contained";
    return (
      <LegacyButton
        ref={ref}
        {...props}
        variant={variant}
        color={enabled ? "primary" : "secondary"}
        sx={{
          padding: pxToRem(12),
          fontSize: pxToRem(16),
          borderWidth: "1px",
          ...(enabled
            ? {
                backgroundColor: "peacock300",
                borderColor: "peacock300",
                "&:hover:not(:disabled), &:focus:not(:disabled)": {
                  backgroundColor: "peacock300",
                },
                "&:active:not(:disabled)": {
                  backgroundColor: "peacock300",
                },
              }
            : {
                borderColor: "gray300",
                color: "text",
                "&:hover:not(:disabled), &:focus:not(:disabled)": {
                  color: "text",
                  borderColor: "peacock300",
                  // new primary.100
                  backgroundColor: "#E0E5FF",
                },
                "&:active:not(:disabled)": {
                  color: "text",
                },
              }),
          ...props.sx,
        }}
      />
    );
  }
);
