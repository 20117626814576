import type { IPContext } from "@outschool/iplookup-client";

import { isLocalStorageSupported } from "@outschool/local-storage";
import React from "react";

import AnalyticsLoader from "../components/AnalyticsLoader";
import pkg from "../lib/pkg";
import { AnalyticsError, isBotRequest, logDebug } from "../lib/util";
import { createPlugins } from "../plugins";
import ComponentTrackingProvider from "./ComponentTrackingProvider";

import type { PageContext } from "./PageContext";
import type { AnalyticsPlugin } from "../plugins";
import type {
  EventOptions,
  LoadOptions,
  QueuedEvent,
  QueuedPayload,
  SegmentAnalyticsNext,
} from "../types";

interface WindowAfterLoad extends Window {
  analytics: SegmentAnalyticsNext;
}

declare const window: WindowAfterLoad;

export enum Integration {
  AdWords = "AdWords",
  AdotOne = "OS AdotOne",
  A8 = "OS A8",
  Awin = "OS Awin",
  BingAds = "Bing Ads",
  Enrichment = "OS Enrichment",
  Facebook = "OS Facebook",
  FacebookPixel = "Facebook Pixel",
  GoogleAnalytics = "Google Analytics",
  GoogleTagManager = "Google Tag Manager",
  HubSpot = "HubSpot",
  Intercom = "Intercom",
  Iterable = "Iterable",
  Kakao = "OS Kakao",
  Naver = "OS Naver",
  PinterestTag = "Pinterest Tag",
  Segment = "Segment.io",
  SocialLadder = "OS Social Ladder",
  TwitterAds = "Twitter Ads",
  TikTokConversions = "TikTok Conversions",
}

export enum IntegrationCategory {
  Required = "Required",
  Functional = "Functional",
  Advertising = "Advertising",
}

export enum AnalyticsMode {
  Enabled = "Enabled",
  Limited = "Limited",
  Off = "Off",
  Unknown = "Unknown",
}

export enum AnalyticsModeSource {
  AdSettings = "AdSettings",
  CookieSettings = "CookieSettings",
  FallbackSettings = "FallbackSettings",
  Unknown = "Unknown",
}

/*
 * Disabled - prevents analytics from loading (useful for bot traffic)
 * Init - waiting on analytics.js to load
 * Loading - waiting on analytics.ready to fire
 * Ready - analytics.ready called
 * Failed - analytics failed to load
 */
export enum AnalyticsStatus {
  Disabled = "Disabled",
  Init = "Init",
  Loading = "Loading",
  Ready = "Ready",
  Failed = "Failed",
}

type Context = {
  initialized: boolean;
  setStatus: (status: AnalyticsStatus) => void;
  status: AnalyticsStatus;
};

const AnalyticsContext = React.createContext<Context | undefined>(undefined);

/*
 * RequiredIntegrations are loaded by default and are included by default on
 * every event.
 */
export const RequiredIntegrations: EventOptions["integrations"] = {
  All: false,
  [Integration.Enrichment]: true,
  [Integration.Segment]: true,
};

/*
 * FunctionalIntegrations are loaded by default, but are not included by default
 * on events. You can opt in to sending events to FunctionalIntegrations when
 * the AnalyticsMode is Limited OR Enabled.
 */
export const FunctionalIntegrations: EventOptions["integrations"] = {
  [Integration.Intercom]: true,
};

/*
 * AdvertisingIntegrations are only loaded when the AnalyticsMode is Enabled.
 * You can opt in to sending events to AdvertisingIntegrations if the
 * AnalyticsMode is Enabled. Otherwise, AdvertisingIntegrations are ignored.
 */
export const AdvertisingIntegrations: EventOptions["integrations"] = {
  [Integration.AdWords]: true,
  [Integration.AdotOne]: true,
  [Integration.A8]: true,
  [Integration.Awin]: true,
  [Integration.BingAds]: true,
  [Integration.Facebook]: true,
  [Integration.FacebookPixel]: { dataProcessingOptions: [[]] },
  [Integration.GoogleAnalytics]: true,
  [Integration.GoogleTagManager]: true,
  [Integration.Iterable]: true,
  [Integration.Naver]: true,
  [Integration.Kakao]: true,
  [Integration.PinterestTag]: true,
  [Integration.SocialLadder]: true,
  [Integration.TwitterAds]: true,
  [Integration.TikTokConversions]: true,
};

export const Integrations: EventOptions["integrations"] = {
  ...AdvertisingIntegrations,
  ...FunctionalIntegrations,
  ...RequiredIntegrations,
};

/*
 * A length limit is used on the queue to avoid a memory leak in cases
 * where a user never accepts or declines cookie, or Segment fails to load
 * in some way that I didn't account for.
 */
const loadableIntegrationList: Array<Integration> = [];
const eventQueue: Array<QueuedPayload> = [];
const priorityEventQueue: Array<QueuedPayload> = [];

/*
 * The status is set in this scope (in addition to the context) so it can
 * be reliably accessed by the performance observers (which function
 * outside of the React context). This is the only use case.
 */
let analyticsStatus: AnalyticsStatus;
export function AnalyticsProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [status, setStatus] = React.useState<AnalyticsStatus>(
    isBotRequest() ? AnalyticsStatus.Disabled : AnalyticsStatus.Init
  );

  React.useEffect(() => {
    if (!window.analytics) {
      if (pkg.env !== "test") {
        pkg.onError(new AnalyticsError("Snippet is not embedded"));
      }
      setStatus(AnalyticsStatus.Failed);
    }
  }, []);

  React.useEffect(
    function onStatusChange() {
      logDebug("AnalyticsStatus:", status);

      if (isReady(status)) {
        sendQueuedEvents(priorityEventQueue);
        sendQueuedEvents(eventQueue);
      } else if (isOff(status)) {
        clearQueuedEvents();
      }

      analyticsStatus = status;
    },
    [status]
  );

  const value: Context = {
    initialized: isReady(status) || isOff(status),
    status,
    setStatus,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      <ComponentTrackingProvider>{children}</ComponentTrackingProvider>
      <AnalyticsLoader />
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext(): Context {
  const context = React.useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error(
      "useAnalyticsContext called outside of an AnalyticsProvider"
    );
  }

  return context;
}

interface LoadAnalyticsParams {
  analyticsMode: AnalyticsMode;
  analyticsModeSource: AnalyticsModeSource;
  analyticsModeSourceOverride: AnalyticsModeSource | null;
  callback: (err: null | Error) => void;
  locationInfo: IPContext;
  page: PageContext;
}

export async function loadAnalytics({
  analyticsMode,
  analyticsModeSource,
  analyticsModeSourceOverride,
  callback,
  locationInfo,
  page,
}: LoadAnalyticsParams) {
  if (typeof window === "undefined") {
    throw new AnalyticsError("Segment library is client-side only");
  }

  if (!window?.analytics) {
    const error = new AnalyticsError("Segment pre-client failed to load");
    callback(error);
    return;
  }

  /*
   * In this case, an error is bubbled up to the app only to be reported to
   * Sentry. It's assumed analytics has loaded successfully otherwise.
   */
  if (!!window?.analytics?.initialized) {
    pkg.onError(
      new AnalyticsError(
        "Unexpected attempt to load analytics after initialization"
      )
    );
    callback(null);
    return;
  }

  try {
    const options = getMergedLoadOptions(analyticsMode);
    const loadStartTime = Date.now();

    await loadAnalyticsPromise(options);

    addLoadDurationEntry("analyticsLoad", loadStartTime);

    const pluginStartTime = Date.now();
    const plugins = createPlugins({
      analyticsMode,
      analyticsModeSource,
      analyticsModeSourceOverride,
      locationInfo,
      page,
      options,
    });

    setLoadableIntegrationList(options.integrations, plugins);

    await window.analytics.register(...plugins);

    removeUnloadablePluginsFromIntegrationList(plugins);

    logDebug("Integrations:\n  -", loadableIntegrationList.join("\n  - "));

    addLoadDurationEntry("pluginLoad", pluginStartTime);

    sendQueuedEvents(priorityEventQueue);

    callback(null);
  } catch (error) {
    callback(error);
  }
}

function loadAnalyticsPromise(options: EventOptions): Promise<void> {
  const timeoutPromise = new Promise<void>((_resolve, reject) => {
    window.setTimeout(() => {
      reject(new AnalyticsError("analytics.ready timeout"));
    }, pkg.loadTimeLimit);
  });

  const readyPromise = new Promise<void>((resolve, reject) => {
    try {
      window.analytics.ready(() => {
        resolve();
      });

      window.analytics.load(pkg.segmentWriteKey, options);
    } catch (error) {
      reject(new AnalyticsError("Unexpected analytics load failure", error));
    }
  });

  return Promise.race([timeoutPromise, readyPromise]);
}

function removeUnloadablePluginsFromIntegrationList(
  plugins: Array<AnalyticsPlugin>
) {
  plugins.forEach(plugin => {
    if (
      !window.analytics.integrations ||
      !!window.analytics.integrations[plugin.name]
    ) {
      return;
    }

    const index = loadableIntegrationList.findIndex(
      name => name === plugin.name
    );

    if (index >= 0) {
      loadableIntegrationList.splice(index, 1);
      delete window.analytics.integrations[plugin.name];
    }
  });
}

function setLoadableIntegrationList(
  integrations: EventOptions["integrations"],
  plugins: Array<AnalyticsPlugin>
) {
  Object.keys(integrations).forEach((key: Integration) => {
    if (key === ("All" as Integration)) {
      return;
    }

    const index = plugins.findIndex(instance => instance.name === key);
    const match = plugins[index];

    if (match && !match.isLoadable()) {
      delete window.analytics?.integrations?.[match.name];
      plugins.splice(index, 1);
      return;
    }

    loadableIntegrationList.push(key);
  });

  loadableIntegrationList.sort();
}

export function getMergedLoadOptions(
  analyticsMode: AnalyticsMode
): EventOptions {
  const localStorage = isLocalStorageSupported();
  const options: LoadOptions = {
    obfuscate: true,
    integrations: {
      ...RequiredIntegrations,
      [Integration.Segment]: {
        deliveryStrategy: {
          strategy: "batching",
          config: {
            size: 10,
            timeout: 1000,
          },
        },
      },
      ...FunctionalIntegrations,
    },
  };

  if (analyticsMode !== AnalyticsMode.Enabled) {
    return options;
  }

  if (localStorage) {
    const isSellerOrg = localStorage.getItem("isSellerOrg") === "true";

    if (isSellerOrg) {
      options.integrations[Integration.HubSpot] = true;
    }
  }

  const disabledIntegrations: Array<Integration> = [];
  Object.keys(AdvertisingIntegrations).forEach((key: Integration) => {
    if (pkg.disabledIntegrationList.includes(key)) {
      disabledIntegrations.push(key);
      return;
    }

    options.integrations[key] = AdvertisingIntegrations[key];
  });

  if (disabledIntegrations.length > 0) {
    logDebug("Disabled integrations:", disabledIntegrations.join(", "));
  }

  return options;
}

/*
 * Explicit integrations take precedence over any provided event category.
 */
export function getRequestedIntegrations(
  requestedIntegrations?: EventOptions["integrations"],
  category?: IntegrationCategory
): EventOptions["integrations"] {
  const integrations: EventOptions["integrations"] = {};

  switch (category) {
    case IntegrationCategory.Advertising:
      Object.assign(integrations, {
        ...AdvertisingIntegrations,
      });
      break;
    case IntegrationCategory.Functional:
      Object.assign(integrations, {
        ...FunctionalIntegrations,
      });
      break;
  }

  if (!requestedIntegrations) {
    return integrations;
  }

  Object.assign(integrations, {
    ...requestedIntegrations,
  });

  return integrations;
}

export function getMergedEventOptions(
  options?: EventOptions,
  category?: IntegrationCategory
): EventOptions {
  const requestedIntegrations = getRequestedIntegrations(
    options?.integrations,
    category
  );
  const integrations: EventOptions["integrations"] = {
    ...RequiredIntegrations,
  };

  loadableIntegrationList.forEach(key => {
    if (key in RequiredIntegrations) {
      // Required integrations can't be overridden
      return;
    }

    if (key in requestedIntegrations) {
      // Omit or include integrations per event
      integrations[key] = requestedIntegrations[key];
      return;
    }

    // Omit integrations if not explicitly set and not required
    integrations[key] = false;
  });

  return {
    integrations,
  };
}

export function isOff(status: AnalyticsStatus): boolean {
  return (
    status === AnalyticsStatus.Failed || status === AnalyticsStatus.Disabled
  );
}

export function isReady(status: AnalyticsStatus): boolean {
  return status === AnalyticsStatus.Ready;
}

/**
 * The checks for AnalyticsStatus.Ready should not be necessary, but the hooks
 * converted into HOC (see: `src/legacy`) have callbacks that aren't updated
 * when `status` changes like they do elsewhere.
 */
export function enqueueEvent(
  event: QueuedEvent,
  category?: IntegrationCategory
) {
  if (isQueueFull()) {
    return;
  }

  if (isPriorityEvent(event)) {
    if (event[0] === "identify") {
      priorityEventQueue.unshift({ event, category });
    } else {
      priorityEventQueue.push({ event, category });
    }

    if (analyticsStatus === AnalyticsStatus.Ready) {
      sendQueuedEvents(priorityEventQueue);
    }

    return;
  }

  eventQueue.push({ event, category });

  if (analyticsStatus === AnalyticsStatus.Ready) {
    sendQueuedEvents(eventQueue);
  }
}

function isQueueFull() {
  return priorityEventQueue.length + eventQueue.length > pkg.eventQueueLimit;
}

function isPriorityEvent(event: QueuedEvent) {
  const [eventType, name] = event;

  return (
    eventType === "identify" ||
    (eventType === "track" && name === "gtm_identify")
  );
}

function clearQueuedEvents() {
  eventQueue.splice(-eventQueue.length);
  priorityEventQueue.splice(-priorityEventQueue.length);
}

function getQueuedEventArgs(payload: QueuedPayload) {
  const { event, category } = payload;
  const eventArgs = event.splice(-2);
  const options = eventArgs[0] ? (eventArgs[0] as EventOptions) : undefined;
  const callback = eventArgs[1];
  const eventOptions = getMergedEventOptions(options, category);

  return [...event, eventOptions, callback];
}

function sendQueuedEvents(queue: Array<QueuedPayload>) {
  while (queue.length > 0) {
    const payload: QueuedPayload | undefined = queue.shift();

    if (!payload) {
      continue;
    }

    const args = getQueuedEventArgs(payload);
    window.analytics.push(args);
  }
}

type LoadDurationList = Array<{
  label: string;
  duration: number;
}>;

const loadDurationList: LoadDurationList = [];

/**
 * Adds a load timing entry to `loadDurationList`. This information is
 * included in our analytics-initialized event to give us insight into
 * the duration of each phase of analytics' load.
 */
export function addLoadDurationEntry(label: string, startTime: number) {
  const duration = Date.now() - startTime;
  loadDurationList.push({ label, duration });
  logDebug(`${label} duration:`, duration, "ms");
}

export function getLoadDurationList(): LoadDurationList {
  return loadDurationList;
}
