import { Box, Typography } from "@outschool/backpack";
import { ReferrerQuery } from "@outschool/gql-frontend-generated";
import { Trans, useTranslation } from "@outschool/localization";
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import React, { useEffect } from "react";

import { useConfetti } from "../hooks/useConfetti";

export const ReferrerHeader = ({
  referrer,
}: {
  referrer: NonNullable<ReferrerQuery["referrer"]>;
}) => {
  const { t } = useTranslation(
    "ui-components-website\\src\\Modals\\ReferrerHeader"
  );
  const referrerProfile = referrer.leader?.approved
    ? referrer.leader
    : // @ts-ignore TS(2531): Object is possibly 'null'.
      referrer.parent;

  const name = referrer.leader?.approved
    ? referrer.leader.name
    : // @ts-ignore TS(2531): Object is possibly 'null'.
      referrer.parent?.publicName;

  const { confettiCannon, fireConfetti } = useConfetti(confettiOptions);
  useEffect(() => {
    setTimeout(fireConfetti, 750);
  }, [fireConfetti]);

  return (
    <Box
      data-test-id="signup-modal-referral-header"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {confettiCannon}
      {/* @ts-ignore TS(2531): Object is possibly 'null'. */}
      {referrerProfile.photo && !referrerProfile.socialOptOut && (
        <UserHeadshotImage
          data-test-id="referrer-avatar"
          // @ts-ignore TS(2322): Type '{ __typename?: "Leader" | undefined; uid: st... Remove this comment to see the full error message
          user={referrerProfile}
        />
      )}
      <Box
        data-test-id="referrer-message"
        sx={{
          marginBottom: "0.5rem",
          marginTop: "1rem",
          textAlign: "center",
          fontWeight: "fontWeightBold",
        }}
      >
        {/* @ts-ignore TS(2531): Object is possibly 'null'. */}
        {referrerProfile.socialOptOut ? (
          <Trans t={t}>
            You got{" "}
            <Box
              component="span"
              sx={{
                fontSize: "1.5em",
              }}
            >
              $20
            </Box>{" "}
            Outschool credit from your friend
          </Trans>
        ) : (
          <Trans t={t}>
            You got{" "}
            <Box
              component="span"
              sx={{
                fontSize: "1.5em",
              }}
            >
              $20
            </Box>{" "}
            Outschool credit from {{ referrerName: name?.trim() }}
          </Trans>
        )}
      </Box>
      <Typography
        variant="body2"
        sx={{ color: "grey.600", marginBottom: "2rem" }}
      >
        {t("Sign up to claim your credits!")}
      </Typography>
    </Box>
  );
};

const confettiOptions = {
  angle: 90,
  spread: 180,
  startVelocity: 25,
  elementCount: 50,
};
