// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import * as Auth from "@outschool/auth-shared";
import { CookieKeys } from "@outschool/data-schemas";
import { isLocalStorageSupported } from "@outschool/local-storage";
import { websiteHost } from "@outschool/routes";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import Cookies from "js-cookie";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import React from "react";

import {
  destroyEsaSession,
  getEsaSession,
  isEsaSessionPresent,
  getSessionType,
} from "../lib/EsaSession";

// This cookie is stored for ease of adding to our analytics tracking. It
// SHOULD NOT be referenced for determining whether or not the user is impersonating
// to do that one should look at the `isImpersonating` value on the session token.
const SESSION_TOKEN_LOCAL_STORAGE_NAME = "jwt";
const REFRESH_TOKEN_LOCAL_STORAGE_NAME = "jwtRefreshToken";

let localStorage = isLocalStorageSupported();
export const __testOnly_setLocalStorage = (ls: any) => {
  localStorage = ls;
};

interface TokenProviderEnv {
  isTest: boolean;
  isDevelopment: boolean;
}

function updateLoggedInUserExperimentCookie(
  env: Pick<TokenProviderEnv, "isTest" | "isDevelopment">,
  loggedInUserExpUid?: string
) {
  if (!loggedInUserExpUid) {
    Cookies.remove(CookieKeys.LoggedInUserExperimentUid);
  } else {
    Cookies.set(CookieKeys.LoggedInUserExperimentUid, loggedInUserExpUid, {
      expires: 365, // days
      path: "/",
      sameSite: "lax",
      secure: !(env.isTest || env.isDevelopment),
    });
  }
}

function updateIsImpersonatingCookie(
  env: Pick<TokenProviderEnv, "isTest" | "isDevelopment">,
  isImpersonating?: boolean
) {
  if (!!isImpersonating) {
    Cookies.set(CookieKeys.InternalTester, "true", {
      domain: new URL(websiteHost()).hostname,
      expires: 365, // days
      path: "/",
      sameSite: "lax",
      secure: !(env.isTest || env.isDevelopment),
    });
  } else {
    Cookies.remove(CookieKeys.InternalTester);
  }
}

type AuthData = {
  uid: string | null;
  isAdmin: boolean;
  isLeader: boolean;
  isLoggedIn: boolean;
  hasFacebook: boolean;
  hasGoogle: boolean;
  hasApple: boolean;
  hasLine: boolean;
  hasKakao: boolean;
  hasPassword: boolean;
  hasAccount: boolean;
  roles: Auth.Roles[];
};

const DEFAULT_AUTH_DATA: AuthData = {
  uid: null,
  isAdmin: false,
  isLeader: false,
  isLoggedIn: false,
  hasFacebook: false,
  hasGoogle: false,
  hasApple: false,
  hasLine: false,
  hasKakao: false,
  hasPassword: false,
  hasAccount: false,
  roles: [],
};

export enum EsaSessionType {
  CW = "CW",
  MERIT = "MERIT",
}

const TokenContext = React.createContext<{
  getTokens(): {
    sessionToken: string | null;
    refreshToken: string | null;
    decodedToken: Auth.Auth | null;
  };
  setTokens(sessionToken: string | null, refreshToken?: string | null): void;
  authData: AuthData;
  getAuthData(): AuthData;
  esaSessionData: Auth.EsaSessionData;
  isEsaSession: boolean;
  esaSessionType: EsaSessionType | null;
  destroyEsaSession(): void;
  logout(additionalContext?: object): void;
}>({
  getTokens: () => ({
    sessionToken: null,
    decodedToken: null,
    refreshToken: null,
    esaToken: null,
  }),
  setTokens: (_s: string | null) => null,
  authData: { ...DEFAULT_AUTH_DATA },
  getAuthData: () => ({ ...DEFAULT_AUTH_DATA }),
  esaSessionData: {},
  isEsaSession: false,
  esaSessionType: null,
  destroyEsaSession: () => {},
  logout: () => {},
});

function setTokensInLocalStorage(
  sessionToken: string | null,
  refreshToken?: string | null
) {
  if (localStorage) {
    if (!!sessionToken) {
      localStorage.setItem(SESSION_TOKEN_LOCAL_STORAGE_NAME, sessionToken);
    } else {
      localStorage.removeItem(SESSION_TOKEN_LOCAL_STORAGE_NAME);
    }
    if (!!refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_NAME, refreshToken);
    } else {
      localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_NAME);
    }
  }
}

interface TokenProviderProps extends TokenProviderEnv {
  onLogout?: () => void;
}

export const TokenProvider = ({
  isTest,
  isDevelopment,
  onLogout,
  children,
}: React.PropsWithChildren<TokenProviderProps>) => {
  const analytics = useAnalytics();
  const trackEvent = useTrackEvent();

  let initialTokenStr: string | null = null;
  let refreshTokenStr: string | null = null;

  const oauth2SessionToken = Cookies.get(Auth.OAUTH2_SESSION_TOKEN);
  const oauth2RefreshToken = Cookies.get(Auth.OAUTH2_REFRESH_TOKEN);
  const switchUserToken = Cookies.get(Auth.SWITCH_USER_TOKEN);
  const parentTransferToken = Cookies.get(
    Auth.PARENT_TRANSFER_TOKEN_COOKIE_NAME
  );
  if (oauth2SessionToken) {
    initialTokenStr = oauth2SessionToken;
    Cookies.remove(Auth.OAUTH2_SESSION_TOKEN);
    refreshTokenStr = oauth2RefreshToken ?? null;
    Cookies.remove(Auth.OAUTH2_REFRESH_TOKEN);
  } else if (switchUserToken) {
    initialTokenStr = switchUserToken;
    Cookies.remove(Auth.SWITCH_USER_TOKEN);
  } else if (parentTransferToken) {
    if (localStorage) {
      localStorage.removeItem(Auth.LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME);
    }
    initialTokenStr = parentTransferToken;
    Cookies.remove(Auth.PARENT_TRANSFER_TOKEN_COOKIE_NAME);
  } else if (localStorage) {
    try {
      initialTokenStr = localStorage.getItem(SESSION_TOKEN_LOCAL_STORAGE_NAME);
      refreshTokenStr = localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_NAME);
    } catch (e) {
      console.log(e);
    }
  }
  // Make sure that localstorage matches the tokens we're using in mem
  setTokensInLocalStorage(initialTokenStr, refreshTokenStr);

  const sessionToken = React.useRef<string | null>(initialTokenStr ?? null);
  const decodedToken = React.useRef<Auth.Auth | null>(
    Auth.decodeToken(initialTokenStr) as Auth.Auth
  );
  const refreshToken = React.useRef<string | null>(refreshTokenStr ?? null);

  const [isEsaSession, setIsEsaSession] = React.useState(isEsaSessionPresent());
  const [esaSessionData, setEsaSessionData] = React.useState(
    getEsaSession() || {}
  );
  const [esaSessionType, setEsaSessionType] =
    React.useState<EsaSessionType | null>(getSessionType());

  const destroyEsaSessionAndRefresh = React.useCallback(() => {
    destroyEsaSession();
    setIsEsaSession(false);
    setEsaSessionType(null);
    setEsaSessionData({});
  }, []);

  const getAuthData = React.useCallback(() => {
    // Use the refresh token for decoded values (longer-lived / won't be expired)
    const _decodedToken = !Auth.hasExpired(decodedToken.current)
      ? decodedToken.current
      : (Auth.decodeToken(refreshToken.current) as Auth.Auth);

    updateIsImpersonatingCookie(
      { isTest, isDevelopment },
      _decodedToken?.isImpersonating
    );
    updateLoggedInUserExperimentCookie(
      { isTest, isDevelopment },
      _decodedToken?.uid
    );

    if (!_decodedToken) {
      // Only remove token if there was a previous session that we can't refresh
      if (!!decodedToken.current && isEsaSessionPresent()) {
        destroyEsaSessionAndRefresh();
      }

      return { ...DEFAULT_AUTH_DATA };
    }
    const isLoggedIn = Auth.isLoggedIn(_decodedToken);

    return {
      uid: _decodedToken?.uid ?? null,
      isLoggedIn,
      isAdmin: isLoggedIn && Auth.isAdmin(_decodedToken),
      isLeader: isLoggedIn && Auth.isLeader(_decodedToken),
      adminForOrganizationType:
        isLoggedIn && _decodedToken?.adminForOrganizationType,
      hasFacebook: Auth.hasFacebook(_decodedToken),
      hasLine: Auth.hasLine(_decodedToken),
      hasKakao: Auth.hasKakao(_decodedToken),
      hasGoogle: Auth.hasGoogle(_decodedToken),
      hasApple: Auth.hasApple(_decodedToken),
      hasPassword: Auth.hasPassword(_decodedToken),
      hasAccount: Auth.hasAccount(_decodedToken),
      roles: Auth.getRoles(_decodedToken),
    };
  }, [destroyEsaSessionAndRefresh, isTest, isDevelopment]);

  const [authData, setAuthData] = React.useState<AuthData>(getAuthData());

  const getTokens = React.useCallback(() => {
    return {
      sessionToken: sessionToken.current,
      decodedToken: decodedToken.current,
      refreshToken: refreshToken.current,
    };
  }, []);

  const setTokens = React.useCallback(
    (_sessionToken: string | null, _refreshToken?: string | null) => {
      if (_sessionToken) {
        const oldDecodedToken = decodedToken.current;
        const newDecodedToken = Auth.decodeToken(_sessionToken) as Auth.Auth;
        sessionToken.current = _sessionToken;
        decodedToken.current = newDecodedToken;
        updateIsImpersonatingCookie(
          { isTest, isDevelopment },
          newDecodedToken?.isImpersonating
        );
        updateLoggedInUserExperimentCookie(
          { isTest, isDevelopment },
          newDecodedToken?.uid
        );
        refreshToken.current = _refreshToken ?? null;

        setTokensInLocalStorage(_sessionToken, _refreshToken);
        if (
          !isEqual(
            omit(newDecodedToken, ["iat", "exp"]),
            omit(oldDecodedToken, ["iat", "exp"])
          )
        ) {
          setAuthData(getAuthData());
        }
      }
    },
    [getAuthData, isTest, isDevelopment]
  );

  const resetTokens = React.useCallback(() => {
    sessionToken.current = null;
    refreshToken.current = null;
    decodedToken.current = null;
    if (localStorage) {
      localStorage.removeItem(SESSION_TOKEN_LOCAL_STORAGE_NAME);
      localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_NAME);
    }
    Cookies.remove("sessionToken", { path: "/users/connect/" });
    Cookies.remove(CookieKeys.OsRef, { path: "/" });
    Cookies.remove("os-referrals", { path: "/" });
    destroyEsaSessionAndRefresh();
    updateLoggedInUserExperimentCookie({ isTest, isDevelopment });
    updateIsImpersonatingCookie({ isTest, isDevelopment });
    setAuthData({ ...DEFAULT_AUTH_DATA });
  }, [destroyEsaSessionAndRefresh, isTest, isDevelopment]);

  const logout = React.useCallback(
    (additionalContext?: object) => {
      if (!isTest) {
        console.log("logoutCurrentUser");
      }

      trackEvent("logout", additionalContext);
      // Clear all local storage as a security precaution
      localStorage?.clear();
      analytics.reset();
      resetTokens();

      onLogout?.();
    },
    [isTest, resetTokens, analytics, onLogout, trackEvent]
  );

  React.useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (
        e.key === REFRESH_TOKEN_LOCAL_STORAGE_NAME &&
        !e.newValue &&
        getTokens().refreshToken
      ) {
        resetTokens();
      }
    };

    if (localStorage) {
      window.addEventListener("storage", listener);

      return () => {
        window.removeEventListener("storage", listener);
      };
    }

    return () => {};
  }, [getTokens, resetTokens]);

  const providerValues = React.useMemo(
    () => ({
      getTokens,
      setTokens,
      authData,
      getAuthData,
      esaSessionData,
      isEsaSession,
      esaSessionType,
      destroyEsaSession: destroyEsaSessionAndRefresh,
      logout,
    }),
    [
      getTokens,
      setTokens,
      authData,
      getAuthData,
      esaSessionData,
      isEsaSession,
      esaSessionType,
      destroyEsaSessionAndRefresh,
      logout,
    ]
  );

  return (
    <TokenContext.Provider value={providerValues}>
      {children}
    </TokenContext.Provider>
  );
};

export const useTokenContext = () => {
  const tokenContext = React.useContext(TokenContext);
  if (!tokenContext) {
    throw new Error("Called useTokenContext outside of a TokenProvider");
  }
  return tokenContext;
};

export function useCurrentUserHasRole(role: Auth.Roles) {
  const { getTokens } = useTokenContext();
  return Auth.hasRole(getTokens().decodedToken, role);
}

export function useIsImpersonating() {
  const { getTokens } = useTokenContext();
  return !!getTokens()?.decodedToken?.isImpersonating;
}
