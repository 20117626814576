import { isLocalStorageSupported } from "@outschool/local-storage";
import { v4 } from "uuid";

type TrackingSessionData = {
  id: string;
  lastAccess: number;
};

const TRACKING_SESSION_KEY = "active_tracking_session";
const INACTIVITY_LIMIT = 1000 * 60 * 30; // 30 min
const REFRESH_LIMIT = 1000 * 60; // only write once per minute

const localStorage = isLocalStorageSupported();
let lastTrackingData: TrackingSessionData = readSessionData();

function readSessionData(): TrackingSessionData {
  const stored = localStorage?.getItem(TRACKING_SESSION_KEY);
  const parsed = stored && JSON.parse(stored);
  return parsed || { id: null, lastAccess: 0 };
}

function writeSessionData(trackingData: TrackingSessionData) {
  localStorage?.setItem(TRACKING_SESSION_KEY, JSON.stringify(trackingData));
}

function rebuildSession() {
  const storedData = readSessionData();
  const storedDataExpired =
    Date.now() - storedData.lastAccess > INACTIVITY_LIMIT;

  lastTrackingData = {
    id: storedDataExpired ? v4() : storedData.id,
    lastAccess: Date.now(),
  };
  writeSessionData(lastTrackingData);
}

function refreshSession() {
  lastTrackingData = { ...lastTrackingData, lastAccess: Date.now() };
  writeSessionData(lastTrackingData);
}

// Returns a uid that resets after INACTIVITY_LIMIT duration
// without this method being called, with the intention of
// correlating events within the same browsing session
export function getTrackingSessionUid(): undefined | string {
  if (!localStorage) {
    return undefined;
  }
  const now = Date.now();
  if (now - lastTrackingData.lastAccess > INACTIVITY_LIMIT) {
    rebuildSession();
  } else if (now - lastTrackingData.lastAccess > REFRESH_LIMIT) {
    refreshSession();
  }
  return lastTrackingData.id;
}
