// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import type { SxProps } from "@outschool/backpack";

import { useIsMobile } from "@outschool/ui-utils";
import React from "react";

import DesktopCategoryNavbar from "./DesktopCategoryNavbar";
import MobileCategoryNavbar from "./MobileCategoryNavbar";
import useNavbarCategoriesFiltered from "./navbarCategories";

export default function CategoryNavbar({ sx = {} }: { sx?: SxProps }) {
  const isMobile = useIsMobile();

  const navbarCategoriesFiltered = useNavbarCategoriesFiltered();

  return (
    <nav>
      {isMobile ? (
        <MobileCategoryNavbar categories={navbarCategoriesFiltered} sx={sx} />
      ) : (
        <DesktopCategoryNavbar categories={navbarCategoriesFiltered} sx={sx} />
      )}
    </nav>
  );
}
